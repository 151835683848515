article.post,
section.bio,
section {
	font-family: "Equity Text A", sans-serif;
	font-size: 2.0em;
	line-height: 1.4;
	grid-column: content;
	z-index: 1000;
	
	@include xs {
		font-size: 1.8em;
	}

	* {
		font-synthesis: none;
		text-rendering: optimizeLegibility;
		font-variant-numeric: oldstyle-nums;
		font-feature-settings: 'rvrn' 1, 'calt' 1, 'kern' 1, 'liga' 1, 'ss03' 1, 'ss10' 0, 'ss15' 1, 'ss18' 1;
	}

	& p, & blockquote,
	& h1, & h2,
	& ol, & ul, & hr,
	& div.footnotes {
		margin-bottom: 1em;
		@include phone {
			margin-left: $phone-margin;
			margin-right: $phone-margin;
		}
	}

	em {
		font-family: "Equity Text A italic", serif;
	}

	strong {
		font-family: "Equity Caps A", serif;
		text-transform: lowercase;
		font-feature-settings: "onum" 1;
	}

	p {
		margin-bottom: 1em;
	}

	h1 {
		font-family: "Bild", sans-serif;
		font-size: 4.0em;
		line-height: 1;
		margin-bottom: 0.4em;
		color: #000;

		em {
			font-family: "Bild", sans-serif;
		}
	}

	h6 {
		font-family: "Bild", sans-serif;
		font-size: 1.2em;
		letter-spacing: 1px;
		font-feature-settings: 'calt' 0;
		color: #999;
		margin-top: -1em;
		margin-bottom: 1em;
		
		@include phone {
			margin-left: $phone-margin;	
		}
		
		span.tag {
			background-color: #fff;
			border: 2px solid #ddd;
			border-radius: 1em;
			display: inline-block;
			padding: 0em 0.5em;
		}
	}

	span.datetime,
	h3 {
		font-family: "Bild", sans-serif;
		font-size: 1.6em;
		line-height: 1.25;
		text-transform: uppercase;
		display: block;
		letter-spacing: 1px;
		color: #999;
		padding-top: 6em;

		@include phone {
			margin-left: $phone-margin;
			margin-right: $phone-margin;
		}
	}

	span.datetime.notitle {
		margin-bottom: 0.2em;
	}

	h2 {
		// float: left;
		font-size: 1.0em;
		font-family: "Equity Text A bold", sans-serif;
		letter-spacing: 0;
		margin-bottom: 0;
		padding-top: 1em;
	}

	blockquote {
		padding-left: 0.6em;

		& p {
			border-left: 0.2em solid #ccc;
			width: 95%;
			font-size: 0.92em;
			margin-left: 0;
			padding-left: 0.6em;
		}
	}

	& ul, & ol {
		width: 27em;

		@include xs {
			max-width: 90%;
		}

		& li {
			margin-bottom: 0.2em;
			
			@include xs {
				margin-left: 1em;
			}
		}
	}

	& ol {
		list-style-type: lower-alpha;
		@include sm {
			margin-left: 1.6em;
		}
		
		& li {
			@include xs {
				margin-left: 1.5em;
			}
		}
	}

	& ul {
		list-style-type: square;
		@include sm {
			margin-left: 1.2em;
		}
	}
	p + ul,
	p + ol {
		margin-top: -0.5em;
	}

	& a, & a:hover {
		color: #a30;
	}

	code {
		font-family: ui-monospace, 'DejaVu Sans Mono', Menlo, Consolas, monospace;
		background-color: #eee;
		font-weight: 500;
		font-size: 0.85em;
	}
	p code,
	li code {
		display: inline-block;
		padding: 1px;

		@include xs {
			overflow-wrap: anywhere;
		}
	}

	figure.highlight {
		 margin: 1em 0;
		width: 100%;

		@include md {
			width: 110%;
			margin-left: -5%;
		}

		@include lg {
			width: 140%;
			margin-left: -20%;
		}

		color: #f8f8f2;
		font-size: 0.8em;
		overflow: scroll;

		td.gutter.gl pre.lineno {
			padding: 0.5em 0.1em 0.5em 0.7em;
			// padding-right: 0.1em;
			color: #6272a4;
		}
		td.code pre {
			padding: 0.5em;
		}
	}
	figure.highlight.small {
		width: 100%;
		margin-left: 0;
	}

	img {
		margin: 1em 0 0 0;
		width: 100%;

		@include md {
			width: 110%;
			margin-left: -5%;
		}

		@include lg {
			width: 120%;
			margin-left: -10%;
		}

	}

	p.caption {
		margin-top: -1em;
		font-family: "Concourse T3", sans-serif;
		color: #aaa;
		padding-bottom: 1em;
		font-size: 0.8em;

		strong {
			font-family: "Concourse T3 bold", sans-serif;
			text-transform: none;
		}

		span.insta {
			float: right;
			font-size: 0.8em;

			a, a:visited {
				color: #aaa;
			}
		}
	}

	hr {
		border: 0;
		height: 2em;

		&::after {
			content: "•••";
			// width: %;
			display: block;
			text-align: center;
			color: #ccc;
			letter-spacing: 0.6em;
		}
	}

	sup {
		font-family: "Concourse C3 bold", sans-serif;
		font-size: 0.8em;
		vertical-align: top;

		& a {
			background: #eee;
			display: inline-block;
			border-radius: 0.6em;
			padding: 0.1em 0.3em;
			text-decoration: none;
			color: #222;

			&:hover {
				background: #fb4;
				color: black;
			}
		}
	}

	div.headshot {
		width: 100%;
		margin: 0;
		margin-bottom: 1.5em;
		margin-top: 1em;
		padding: 0;

		img {
			margin: 0;
			width: 100%;
			border-radius: 0.5em;
			
			@include phone {
				border-radius: 0;
			}
		}

		p.caption {
			margin-top: 0;
			// margin-left: 0.5em;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
}

article.banner span.datetime {
	padding-top: 3em;
}

section.bio {
	h1.post-title {
		padding-top: 2em;
	}

	p {
		font-size: 90%;
	}
	p.preamble {
		font-size: 125%;
		margin-bottom: 0.8em;
	}

	@include md {
		div#lectures {
			float: left;
			width: 68%;
		}
		div#external {
			float: right;
		}
	}

	h3 {
		padding-top: 0;
		// margin-bottom: 0.25em;
		// color: #000;
	}
	h3.clear {
		padding-top: 0.5em;
	}

	h3 + ol {
		width: 95%;
		margin-top: 0.5em;
		font-size: 85%;

		li {
			list-style-type: decimal;
			font-feature-settings: 'ss02' 1;
		}
	}

	dl {
		margin-bottom: 1em;
		
		@include phone {
			margin-left: $phone-margin;
			margin-right: $phone-margin;
		}

		dd {
			font-size: 90%;
			margin-bottom: 0.5em;
		}

		dt {
			font-family: "Concourse C3", sans-serif;
			// color: #aaa;
			text-transform: lowercase;
			font-size: 0.8em;
			font-feature-settings: 'rvrn' 1, 'calt' 0;
			letter-spacing: 1px;
		}

		dt.location {
			margin-top: -0.4em;
			margin-bottom: 1em;
		}
	}
}

div.footnotes {
	border-top: 0.1em solid #ccc;
	margin-top: 3em;
}
div.footnotes ol {
	list-style-type: decimal;
	font-size: 0.8em;
	margin-top: 1em;
	margin-left: 2em;
	width: 90%;
	padding: 0;

	& li p {
		margin-bottom: 0.3em;
		margin-left: 0;
		width: 100%;
	}
	
	& li {
		@include xs {
			margin-left: 0;
		}
	}
}

div.banner {
	font-size: 67.5%;
	
	@include xs {
		padding-top: 6.75em;
	}

	img {
		width: 100%;
		margin: 0 auto;
		display: block;
	}

	div.caption {
		margin: 0 5px;

		@include sm {
			width: 60em;
			margin: 0 auto;
		}

		p {
			margin-top: 0.5em;
			font-family: "Concourse T3", sans-serif;
			color: #aaa;
			font-size: 1.6em;

			strong {
				font-family: "Concourse T3 bold", sans-serif;
			}
		}

	}
}
