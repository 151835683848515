div#blog-main {

	section {
		h1.post-title {
			padding-top: 1em;
		}

		p, li {
			font-size: 90%;
		}
		p.preamble {
			font-size: 125%;
			margin-bottom: 0.8em;
		}

		h3 {
			padding: 0;
			margin-top: 1em;
		}

		h3 + p {
			margin-top: 0.5em;
		}

		p.caption {
			font-size: 0.75em;
		}
	}

	section.post-navigation {
		margin-top: 2em;

		@include lg {
			width: 136%;
			margin-left: -22.5%;
			padding: 1em 1.5em 2em 1.5em;
			border: 1px solid #e4e4e4;
		}
		
		ul.posts {
			list-style-type: none;
			margin: 0;
			padding-top: 1em;
			font-size: 85%;
			
			@include lg {
				display: flex;
				flex-flow: column wrap;
				justify-content: flex-start;
				align-content: flex-start;
				column-gap: 3%;
				max-height: 42em;
			}
			
			li {
				margin-left: 0;
				
				@include phone {
					margin-left: $phone-margin;
				}
				
				@include lg {
					width: 57%;
					margin: auto;
				}
				
				span.date {
					font-family: "Concourse T3", sans-serif;
					color: #646464;
					padding-left: 0.25em;
				}
			}
			
			li.year {
				// font-family: "Concourse C3 bold", sans-serif;
				font-family: "Concourse C3", sans-serif;
				text-transform: lowercase;
				// font-size: 0.8em;
				font-feature-settings: 'rvrn' 1, 'calt' 0;
				letter-spacing: 1px;
			}
			li ~ li.year { 
				margin-top: 1.52em;
			}
		}
		
		h3 {
			margin-top: 0;
		}
	}
}