@viewport {
    width: device-width;
}

:root {
    font-size: 100%;
}

body {
    background-color: #000;
}

div.wrapper {
    background-color: #fff;
    min-height: 100vh;
    padding-bottom: 1em;
}

main {
    width: 100%;
    margin: 0 auto;
    font-size: 67.5%;

    @include sm {
        width: 60em;
    }
}

.clear {
    clear: both;
}

// ***** HEADER

header {
    position: fixed;
    top: 0;
    padding-top: 2em;
    padding-bottom: 1em;
    width: 60em;

    @include xs {
        max-width: 100%;
        padding-top: 0;
    }    
}

nav {
    text-align: center;
    margin-top: 1em;
    margin: 0 auto;
    
    @include xs {
        margin: 0;
        text-align: center;
    }

    ul {
        display: inline-block;
        padding: 1em 2.5em 0.2em 2.5em;
        border-radius: 2em;
        background: rgba(240, 240, 240, 0.8);
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        
        @include xs {
            border-radius: 0;
            display: block;
        }
    }

    li {
        display: inline-block;
        margin-left: 2em;
        margin-bottom: 0.6em;
        font-family: "Concourse C3", sans-serif;
        text-transform: lowercase;
        font-size: 1.4em;
        letter-spacing: 0.1em;
        line-height: 1.2;
        color: #646464;
        vertical-align: middle;
    }

    li.byline {
        font-family: "Concourse C3 bold", sans-serif;
        text-transform: lowercase;
        font-size: 1.8em;
        color: #000;
        margin-left: 0;
        margin-bottom: 0.5em;
    }
    
    @include xs {
        li.byline {
            text-align: center;
            display: block;
        }

        li {
            margin-left: 1em;
            // display: block;
        }
    }

    a {
        color: #646464;
        text-decoration: none;

        &:hover {
            border-bottom: 1px solid #646464;
        }
    }

    span.current,
    a.current,
    span.year,
    span.title {
        color: #000;
        border-bottom: 1px solid #000;
    }

    span.year {
        // font-size: 85%;
        font-feature-settings: "onum" 1;
    }

    span.title {
        text-transform: lowercase;
    }
}

footer {
    font-family: "Concourse T3", sans-serif;

    font-size: 1.8em;
    line-height: 1.2;
    text-align: center;
    margin-top: 2em;
    padding-bottom: 1em;

    text-rendering: optimizeLegibility;
    font-feature-settings: 'rvrn' 1, 'calt' 1, 'kern' 1, 'liga' 1, 'ss03' 1, 'ss10' 0, 'ss15' 1, 'ss18' 1;

    #copyright {
        color: #aaa;
        margin-top: 1em;
        
        @include xs {
            span.divider {
                visibility: hidden;
            }
            span.made {
                display: block;
            }
        }
    }
}

#site-title {
    border-radius: 17px;
    margin-top: 2em;
    margin-bottom: 1em;
}

// div.masthead {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 10;
//     // background: rgba(255, 255, 255, 0.98);
//     background-image: linear-gradient(180deg, rgba(255,255,255,0.98) 70%, rgba(255,255,255,0), );
//     padding: 1.2em 0 2.4em 0;
//     width: 100%;
//     font: 1.8em/1.35em "Equity Caps A", sans-serif;
//     letter-spacing: 1px;
//     text-transform: lowercase;
//     text-align: center;
//
//     a, a:visited {
//         color: #000;
//         text-decoration: none;
//     }
//
//     a:hover {
//         text-decoration: underline;
//     }
// }
