div#fiction-main {
    padding-top: 12em;
    font-size: 67.5%;

    font-family: "Equity Text A", serif;
    font-synthesis: none;
    text-rendering: optimizeLegibility;

    ul, ol {
        padding-bottom: 1.5em;
        font-feature-settings: 'onum' 1, 'rvrn' 1, 'calt' 1, 'kern' 1, 'liga' 1, 'ss03' 1, 'ss10' 0, 'ss15' 1, 'ss18' 1;
    }

    ul li,
    ol li {
        font-size: 2.8em;
        line-height: 1.5em;
    }

    ul li span.date {
        font-family: "Concourse C3", sans-serif;
        text-transform: uppercase;
        color: #999;
        font-size: 0.7em;
        margin-left: 0.5em;
        font-feature-settings: 'onum' 0;
    }

    h3 {
        font-family: "Concourse C3 bold", sans-serif;
        text-transform: uppercase;
        font-size: 2.0em;
        letter-spacing: 0.1em;
        margin-bottom: 0.5em;
        margin-top: 1em;
    }
}

article.fiction {
    font-family: "Equity Text A", serif;
    font-size: 2.0em;
    line-height: 1.4;
    grid-column: content;
    z-index: 1000;
    padding: 6em 1.5em 0 1.5em;

    @include xs {
        padding-left: 0;
        padding-right: 0;
        font-size: 1.8em;
    }

    * {
        font-synthesis: none;
        text-rendering: optimizeLegibility;
        font-variant-numeric: oldstyle-nums;
        font-feature-settings: 'rvrn' 1, 'calt' 1, 'kern' 1, 'liga' 1, 'ss03' 1, 'ss10' 0, 'ss15' 1, 'ss18' 1;
    }

    & p, & blockquote,
    & h1, & h2,
    & ol, & ul, & hr,
    & div.footnotes {
        // margin-bottom: 1em;
        @include xs {
            margin-left: $phone-margin;
            margin-right: $phone-margin;
        }
    }

    & p {
        text-indent: 1em;
        text-align: justify;
        
        @include xs {
            // text-indent: 2em;
            text-align: left;
        }
        
    }

    & h1 {
        font-size: 1.2em;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        text-align: center;
        margin-bottom: 3em;
    }

    h1 + p,
    hr + p {
        text-indent: 0;
    }

    h4 {
        text-transform: uppercase;
        text-align: center;
        margin: 1em 0;
    }

    hr {
        padding-top: 2em;
        border: 0;
    }

    em {
        font-family: "Equity Text A italic", serif;
    }

    strong {
        font-family: "Equity Caps A", serif;
        text-transform: lowercase;
        font-feature-settings: "onum" 1;
    }
}

div.title-card {
    background-size: 100%;
    background-position-y: 65%;
    width: 120%;
    margin-left: -10%;
    border-radius: 2em;
    
    @include xs {
        width: 100%;
        margin-left: 0;
        border-radius: 0;
    }
}

div#traveler-navigation {
    // background-image: url("/assets/images/banners/03-banker-banner.png");
    background-image: url("/assets/images/banners/01-traveler-banner-sm.jpg");
}

div#traveler-navigation,
div#the-traveler {
    h3 {
        font-family: "Velvet Regular", sans-serif;
        color: #fff;
        font-size: 14.0em;
        letter-spacing: 0;
        text-align: center;
        font-synthesis: none;
        text-rendering: optimizeLegibility;
        font-feature-settings: 'onum' 1, 'rvrn' 1, 'calt' 1, 'kern' 1, 'liga' 1, 'ss03' 1, 'ss10' 0, 'ss15' 1, 'ss18' 1;
        text-shadow: #000 6px 0 54px;
        margin: 0;
        padding-top: 0.5em;
    }

    ol {
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }
    ol li {
        display: inline-block;
        text-align: center;
        font-size: 5.0em;
        margin: 0 0.15em 0.5em 0.15em;

        a, a:visited {
            color: #fff;
            font-family: "Velvet Regular", sans-serif;
            text-decoration: none;
            opacity: 0.5;
            @include xs {
                opacity: 0.75;
            }
            text-shadow: #000 2px 0 20px;
        }
        a:hover {
            opacity: 1.0;
            border-bottom: 0.1em solid #fff;
        }

    }
}

div.banner.fiction div {
    position: absolute;
    top: 9vw;
    width: 100%;
}
div.banner.fiction {
    background: #000;
    
    @include xs {
        background: #fff;
    }
}
div.banner.fiction img {
    opacity: 0.8;
    @include xs {
        opacity: 1.0;
    }
}

div#the-traveler ol {
    padding-bottom: 0;
    font-feature-settings: 'onum' 1, 'rvrn' 1, 'calt' 1, 'kern' 1, 'liga' 1, 'ss03' 1, 'ss10' 0, 'ss15' 1, 'ss18' 1;
    text-transform: uppercase;

    li {
        font-size: 6vw;
        @include xs {
            font-size: 10vw;
        }
    }

    li.selected {
        color: #fff;
        font-family: "Velvet Regular", sans-serif;
        text-shadow: #000 2px 0 20px;
        border-bottom: 0.1em solid #fff;
    }
}

div#the-traveler h3 {
    font-size: 12vw; //10.0em;
    text-transform: uppercase;
    @include xs {
        font-size: 18vw;
    }
}

@include xs {
    div#traveler-navigation h3 {
        font-size: 10em;
    }
    div#the-traveler {
        margin-top: 4em;
    }
}