
@font-face {
    font-family: 'Concourse C3';
    src: url('../fonts/concourse_c3_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Concourse C3 bold';
    src: url('../fonts/concourse_c3_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face
{
    font-family: 'Equity Text A italic';
    src: url('../fonts/equity_text_a_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face
{
    font-family: 'Equity Text A';
    src: url('../fonts/equity_text_a_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face
{
    font-family: 'Equity Text A bold';
    src: url('../fonts/equity_text_a_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Equity Caps A';
    src: url('../fonts/equity_caps_a_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    // src: url('../fonts/BildV2-CompressedBlack.woff2') format("woff2"),
    src: url('../fonts/BildV3-CompressedLight.woff') format("woff");
    font-family: 'Bild';
    font-style: normal;
    font-weight: normal;
}

@font-face {
    src: url('../fonts/BildV2-CompressedBlack.woff') format("woff");
    font-family: 'Bild Black';
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Concourse T3';
    src: url('../fonts/concourse_t3_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Concourse T3 bold';
    src: url('../fonts/concourse_t3_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Velvet Regular';
    src: url('../fonts/velvet-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
